<template>
  <div class="mynode">
    <Nav></Nav>
    <div class="mynodeCon">
      <div class="con1">
        <p><b>{{lang('zhengshijiedian')}}</b></p>
        <p >{{lang('zhengxiang')}}</p>
      </div>
      <div class="con2">
        <div class="conNum">
          <div class="a">
            <p>
              <b>{{ infoObjV2.num }}</b>
            </p>
            <p class="n">{{lang('officialNum')}}
            </p>
          </div>
          <div class="a">
            <p>
              <b>{{ BigNumberStr(infoObjV2.totalAmount,18) }}</b>
            </p>
            <p class="n">{{lang('pledgeNums')}}</p>
          </div>
          <div class="a">
            <p>
              <b>{{ BigNumberStr(infoObjV2.dailyReward, 18,3) }}</b>
            </p>
            <p class="n">{{lang('onedayrevenue')}}</p>
          </div>
        </div>
      </div>
      <div class="con5">
        <div class="title">
          <div class="titleL">
            <span>{{lang('canxuanjeidianliebiao')}}</span>
          </div>
          <div class="titleR">
            <div class="sp">{{lang('fenpeibili')}}</div>
            <div class="sp">{{lang('pledgepeople')}}</div>
            <div class="sp">{{lang('pledgetotal')}}</div>
            <div class="sp">{{lang('totalrevenue')}}</div>
            
          </div>
          <div class="reactivePc">
            <!-- <div class="spp ">{{lang('pledged')}}
              <span class="light "></span>
              <span class="an"></span>
          </div> -->
          </div>
          
        </div>
      </div>
      <div class="con4">
        <div  class="zanwu" v-if="getFormalList.length==0">{{lang('zanwujiedian')}}</div>
        <div
          v-else
          class="conte cursor-pointer"
          v-for="(item, index) in getFormalList"
          :key="index"
        >
          <div class="contL" @click="JumpNode(item)">
            <div class="l">
            <span v-if="index >= 3">{{ index+1 }}</span>
            <span v-if="index < 3"></span>
            <span></span>
            <span>
              <p class="reactivePc"><b>{{cutNameW(item.name)}}</b></p>
              <p class="reactiveMob"><b>{{cutNameH(item.name)}}</b></p>
              <p class="reactivePc">{{cutNameW(item.slogan)}}</p>
              <p class="reactiveMob">{{cutNameH(item.slogan)}}</p>
            </span>
            </div>
           <div class="r">
            <span >{{ item.rate }}%</span>
            <span>{{ item.depositNum }}</span>
            <span>{{ BigNumberStr(item.totalAmount,18,0) }}</span>
            <span>{{ BigNumberStr(item.totalReward,18,0) }}</span>
           </div>
          </div>
          <div class="contR">
            <span class="btn2 cursor-pointer" @click="showVote(item.nodeAddr,item.status,item.name)">{{lang('zhiya')}}</span>
          </div>
        </div>
        
      </div>
    </div>
  </div>
  
</template>
<script>
import Nav from "../../components/Nav/Index";
import { lang ,Toast} from "../../tools";
import { formatDate,cutNameH,cutNameW} from "../../tools/index";
import * as metaMaskTools from "../../tools/chain";
import { mixin1, mixin2, mixin3 } from "../../tools/mixins";
import { busEvent } from "../../components/busEvent.js"
export default {
  name: "node",
  components: { Nav },
  mixins: [mixin1, mixin2, mixin3],
  mounted() { 
  },
  computed: {
    account() {
      return this.$store.state.accounts[0];
    },
  },
  created(){
    let this_ = this
    busEvent.$on('getBus',function(val){
      this_.getFormalNodeList();
      this_.getNodeInfoV2()
    })
  },
  data() {
    return {
      show:false,
      showPopover: false,
      delay: null,
      getList: [],
      getFormalList:[],
      serNum: 0,
      address: "", 
      oTime:'', 
      select:null,
      time:'',
      flagShow:false,
      names:"",
      myObj:0,
      infoObjV2:{
        num:0,
        totalAmount:0,
        dailyReward:0
      }
    };
  },
  methods: {
    initPage() {
      this.getNodeInfoV2()
      // this.getNodeList();
      // this.getCountdown()
      this.getFormalNodeList()
      // this.getNodeMyInfo()
    },
    initData() {
      // this.getCountdown()
    },
    cutNameH(name){
        return cutNameH(name);
    },
    cutNameW(name){
      return cutNameW(name);
    },
    onSelect(action) {
      Toast(action.text);
    },
    showVote(address,status,name) {
      this.$store.commit("setState", {
        voteShow: true,
        selectItem:address,
        selectName:name,
        selectItemAtatus:status
      });
    },
    async getFormalNodeList() {
      var res = await metaMaskTools.getFormalNodeList(this.account,this.flagShow);
      console.log(res,'列表');
      this.getFormalList=res
    },
    // JumpPartIn() {
    //    if(this.oTime=='end'){
    //       Toast(this.lang('jingxuanjieshu'))
    //     } else {
    //       if(this.myObj!=0) {
    //       Toast(this.lang('chuangjianjiedian'))

    //       } else {
    //       this.$router.push({  name: 'partInNode',params:{flagShowVal1:false}});  

    //       }
    //     }
    // },
    // a(){
    //   if(this.time){
    //   this.oTime = formatDate(this.time * 1000);
    //   }
    // },
    async getNodeInfoV2() {
      var res = await metaMaskTools.getNodeInfoV2(this.account);
      console.log(res,'详情');
      this.infoObjV2 = res;
    },
    // async getNodeList(getList) {
    //   var res = await metaMaskTools.getNodeList(this.account,this.flagShow);
    //   this.getList = res;
    // },
    // async getCountdown(format,res) {
    //   var res = await metaMaskTools.getCountdown(this.account);
    //   this.time=res
    //   this.timer=setInterval(this.a,1000)     
    // },
    JumpNode(item) {
      this.address = item.nodeAddr;
      this.names=item.name
      let query = {
                projectId: this.address,
                projectIdNmae:this.names
            };
      // sessionStorage.setItem('address', this.address)
      this.$router.push({
        path: '/nodeList',
        query: query
        } );     
    },
    hide() {
      this.$store.commit("setState", {
        voteShow: false,
      });
    },
    onRefresh(accounts) {
      this.initPage();
    },
    // async getNodeInfo1(infoObj) {
    //   var res = await metaMaskTools.getNodeInfo(this.account);
    //   this.infoObj = res;
    // },
    // async getNodeList(getList) {
    //   var res = await metaMaskTools.getNodeList(this.account,this.flagShow);
    //   this.getList = res;
    // },
    lang(key) {
      return lang(key);
    },
  },
  // beforeDestroy(){clearInterval(this.timer)}

};
</script>
<style scoped lang="less">
.cursor-pointer{
  cursor: pointer;
}
.changeStyle {
  margin-right: 2%;
}
@media (min-width: 769px) {
  .mynodeCon {
  box-sizing: border-box;
  width: 100%;
  padding: 110px 40px 0 240px;
  .dis {
    display: none;
  }
  .con1 {
     z-index: 40;
    position: fixed;
    margin-top: 60px;
    color: #fff;
    p:nth-of-type(2) {
      font-size: 12px;
      margin-top: 8px;
    }
  }
  .con2 {
    margin-top: 140px;
    width: 100%;

    p {
      font-size: 15px;
      color: #fff;
    }
    
    .conNum {
      border-radius: 10px;
      display: flex;
      align-items: center;
      width: 100%;
      height: 80px;
      background: linear-gradient(#0077c5, #0045bb);
      margin-top: 20px;
    
      .a {
        text-align: center;
        width: 33.33%;
        border-right: 1px solid #ccc;
        .n {
          margin-top: 8px;
        }
      }
      .a:nth-of-type(3) {
        border-right: 0;
      }
    }
  }
  .con3 {
    width: 100%;
    margin-top: 25px;
    height: 103px;
    background-color: rgba(#0f111f, 0.4);
    border-radius: 10px;
    font-size: 12px;
    color: #fff;
    padding: 0 15px 0 9.5px;
    .top {
      display: flex;
      justify-content: space-between;
      width: 100%;
      height: 51.5px;
      line-height: 51.5px;
      border-bottom: 1px solid #ccc;
      .topR {
        span:nth-of-type(2) {
          display: inline-block;
          width: 65px;
          height: 22.5px;
          line-height: 22.5px;
          border: 1px solid #0077c5;
          text-align: center;
          margin-left: 20px;
          color: #0077c5;
          border-radius: 10px;
        }
      }
    }
    .bot {
      display: flex;
      justify-content: space-between;
      width: 100%;
      height: 51.5px;
      line-height: 51.5px;
      .botR {
        span:nth-of-type(2) {
          display: inline-block;
          width: 15px;
          height: 15px;
          background: url("../../assets/img/fz.png") no-repeat;
          background-size: 100% 100%;
          margin-left: 20px;
        }
      }
    }
  }
  .con5 {
    margin-top: 25px;
    color: #fff;
    font-size: 12px;
    .title {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
      margin-bottom: 20px;
      .titleL {
        flex: 1;
      }
      .titleR {
        display: flex;
        justify-content: flex-end;
        margin-right: 11%;
         flex: 1;
        .sp {
          display: flex;
          display: inline-block;
          flex: 1;
        }
       
      }
      .spp {
          .light {
                    display: inline-block;
                    width: 30px;
                    height: 15px;
                    background: url("../../assets/img/light.png") no-repeat;
                    background-size: 100% 100%;
                    vertical-align: middle;
                    margin-left: 10px;
                  }
            .an{
              display: inline-block;
              width: 30px;
              height: 15px;
              background: url("../../assets/img/an.png") no-repeat;
              background-size: 100% 100%;
              vertical-align: middle;
              margin-left: 10px;
            }
      }
      
    }
  }
  .con4 {
    margin-top: 20px;
    width: 100%;
    font-size: 10px;
    border-radius: 10px;
    .zanwu {
      font-size: 18px;
      color: rgba(#fff,.3);
      text-align: center;
      margin-top: 40px;
    }
    .conte {
      width: 100%;
      padding: 10px 15px 10px 9.5px;
      background-color: rgba(#0f111f, 0.4);
      display: flex;
      justify-content: space-between;
      color: #fff;
      border-bottom: 1px solid rgba(#ccc, 0.4);
    }
    .conte:last-of-type {
      border-bottom: 0;
    }
    .contL {
      width: 90.15%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
      .l {
        flex: 1;
        span:nth-of-type(1) {
        display: inline-block;
        width: 15px;
        height: 14.5px;
        margin-left: 4px;
        flex: 1;
      }
      span:nth-of-type(2) {
        display: inline-block;
        width: 30px;
        height: 30px;
        background: url("../../assets/img/ytf.png") no-repeat;
        background-size: 100% 100%;
        margin-left: 12px;
        vertical-align: middle;
        flex: 1;
      }
      span:nth-of-type(3) {
        display: flex;
        display: inline-block;
        flex-direction: column;
        justify-content: space-between;
        margin-left: 12px;
        vertical-align: middle;
        flex: 1;
      }
      }
      .r {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        flex: 1;
        span {
          flex: 1;
        }
      }
    }
    .contR {
      flex: 1;
      // width: 7%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .btn2 {
        padding: 0 15px;
        height: 25px;
        line-height: 25px;
        border: 1px solid;
        background: linear-gradient(#0077c5, #0045bb);
        color: #fff;
        border: 0;
        border-radius: 10px;
        // margin-right: 25px;
      }
    }
    .conte:nth-of-type(1) .contL .l span:nth-of-type(1) {
      display: inline-block;
      width: 15px;
      height: 14.5px;
      background: url("../../assets/img/one.png") no-repeat;
      background-size: 100% 100%;
      margin: 0 0 -3px 0;
    }
    .conte:nth-of-type(2) .contL .l span:nth-of-type(1) {
      display: inline-block;
      width: 15px;
      height: 14.5px;
      background: url("../../assets/img/two.png") no-repeat;
      background-size: 100% 100%;
      margin: 0 0 -3px 0;
    }
    .conte:nth-of-type(3) .contL .l span:nth-of-type(1) {
      display: inline-block;
      width: 15px;
      height: 14.5px;
      background: url("../../assets/img/three.png") no-repeat;
      background-size: 100% 100%;
      margin: 0 0 -3px 0;
    }
    p {
      line-height: 20px;
    }
  }
}
}

@media (max-width: 768px) {
 
  .mynodeCon {
    box-sizing: border-box;
    width: 100%;
    padding: 10px 10px 0;
    .dis {
      display: none;
    }
    .con1 {
      margin-top: 20px;
      color: #fff;
      p:nth-of-type(2) {
        font-size: 12px;
        margin-top: 8px;
      }
    }
    .con2 {
      p {
        font-size: 12px;
        color: #fff;
      }
      .conNum {
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 80px;
        background: linear-gradient(#0077c5, #0045bb);
        margin-top: 20px;
        .a {
        text-align: center;
        width: 33.33%;
        border-right: 1px solid #ccc;
        .n {
          margin-top: 8px;
        }
      }
        .a:nth-of-type(3) {
          border-right: 0;
        }
      }
    }
    .con3 {
      width: 100%;
      margin-top: 25px;
      height: 103px;
      background-color: rgba(#0f111f, 0.4);
      border-radius: 10px;
      font-size: 12px;
      color: #fff;
      padding: 0 15px 0 9.5px;
      .top {
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 51.5px;
        line-height: 51.5px;
        border-bottom: 1px solid #ccc;
        .topR {
          span:nth-of-type(2) {
            display: inline-block;
            width: 65px;
            height: 22.5px;
            line-height: 22.5px;
            border: 1px solid #0077c5;
            text-align: center;
            margin-left: 20px;
            color: #0077c5;
            border-radius: 10px;
          }
        }
      }
      .bot {
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 51.5px;
        line-height: 51.5px;
        .botR {
          span:nth-of-type(2) {
            display: inline-block;
            width: 15px;
            height: 15px;
            background: url("../../assets/img/fz.png") no-repeat;
            background-size: 100% 100%;
            margin-left: 20px;
          }
        }
      }
    }
    .con5 {
      margin-top: 25px;
      color: #fff;
      font-size: 0.1rem;
      .title {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
      margin-bottom: 20px;
      .titleL {
        flex: 1;
      }
      .titleR {
        display: flex;
        justify-content: flex-end;
        margin-right: 11%;
         flex: 1;
        .sp {
          display: inline-block;
          flex: 1;
        }
        .sp:nth-of-type(4) {
          display: none;
        }
        .sp:nth-of-type(2) {
          display: none;
        }
        .sp:nth-of-type(5) {
          display: none;
        }
        .sp:nth-of-type(6) {
          display: none;
          width: 30px;
          height: 15px;
          background: url("../../assets/img/light.png") no-repeat;
          background-size: 100% 100%;
          vertical-align: middle;
          margin-left: 10px;
        }
        .sp:nth-of-type(7) {
          width: 30px;
          height: 15px;
          background: url("../../assets/img/an.png") no-repeat;
          background-size: 100% 100%;
          vertical-align: middle;
          margin-left: 10px;
        }
      }
    }
  }
  .con4 {
    margin-top: 20px;
    width: 100%;
    font-size: 10px;
    border-radius: 10px;
    .zanwu {
      font-size: 18px;
      color: rgba(#fff,.3);
      text-align: center;
      margin-top: 40px;
    }
    .conte {
      width: 100%;
      padding: 10px 5px 10px 4.5px;
      background-color: rgba(#0f111f, 0.4);
      display: flex;
      justify-content: space-between;
      height: auto;
      color: #fff;
      border-bottom: 1px solid rgba(#ccc, 0.4);
    }
    .conte:last-of-type {
      border-bottom: 0;
      margin-bottom: 20px;
    }
    .contL {
      width: 90%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      flex: 1;
      .l {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        span:nth-of-type(1) {
        display: inline-block;
        width: 15px;
        height: 14.5px;
        margin-left: 4px;
        flex: .1;
      }
      span:nth-of-type(2) {
        display: none;
      }
      span:nth-of-type(3) {
        
        display: flex;
        display: inline-block;
        flex-direction: column;
        justify-content: space-between;
        margin:0 12px;
        vertical-align: middle;
        font-size: 10px;
        flex: 1;
        p {
          word-wrap: break-word;
          word-break: break-all;
        }
      }
      }
      .r {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        flex: 1;
        span {
          flex: 1;
        }
        span:nth-of-type(4) {
          display: none;
        }
        span:nth-of-type(2) {
          display: none;
        }
      }
    }
    .contR {
      width: 10%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .btn2 {
        white-space:nowrap;
        padding: 0 15px;
        height: 25px;
        line-height: 25px;
        border: 1px solid;
        background: linear-gradient(#0077c5, #0045bb);
        color: #fff;
        border: 0;
        border-radius: 10px;
        margin:0 5px 0 25px;
      }
    }
    .conte:nth-of-type(1) .contL .l span:nth-of-type(1) {
      display: inline-block;
      width: 15px;
      height: 14.5px;
      background: url("../../assets/img/one.png") no-repeat;
      background-size: 100% 100%;
    }
    .conte:nth-of-type(2) .contL .l span:nth-of-type(1) {
      display: inline-block;
      width: 15px;
      height: 14.5px;
      background: url("../../assets/img/two.png") no-repeat;
      background-size: 100% 100%;
    }
    .conte:nth-of-type(3) .contL .l span:nth-of-type(1) {
      display: inline-block;
      width: 15px;
      height: 14.5px;
      background: url("../../assets/img/three.png") no-repeat;
      background-size: 100% 100%;
    }
    p {
      line-height: 20px;
    }
  }
  }
}
</style>
